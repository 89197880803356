<template>
  <div class="app-container">
    <van-popup v-model="visible" position="bottom" :style="{ height: '100%' }">
      <van-cell-group class="block">
        <van-field
          is-link
          readonly
          clickable
          v-model="bank"
          label="银行"
          placeholder="点击选择银行"
          @click="pickerBank"
        />
        <van-field
          readonly
          v-model="realname"
          label="开户人"
          placeholder="请输入"
        />
        <van-field
          v-model="form.card_no"
          type="digit"
          label="银行卡号"
          placeholder="请输入"
        />
      </van-cell-group>
      <van-cell-group class="block" style="padding: 0 20px">
        <van-button
          block
          type="info"
          style="margin-bottom: 10px"
          @click="addCard"
          >确定添加</van-button
        >
        <van-button plain block type="info" @click="visible = false"
          >取消</van-button
        >
      </van-cell-group>
    </van-popup>

    <van-popup v-model="bankVisible" round position="bottom">
      <van-picker
        show-toolbar
        :columns="bankListArr"
        @cancel="bankVisible = false"
        @confirm="onConfirmBank"
      />
    </van-popup>
  </div>
</template>
<script>
import { Notify } from 'vant'
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      visible: false,
      bankVisible: false,
      realname: '',
      bank: '',
      form: {
        opening_name: '无',
        is_default: 2
      },
      bankList: [],
      bankListArr: []
    }
  },
  mounted () {
    this.bankList = this.$store.state.config.bankList
    this.getbankList()
    this.realname = Cookies.get('realname')
  },
  methods: {
    addCard () {
      this.$axios.post('/wxc/card/create', this.form).then(res => {
        if (res.code === 200) {
          this.visible = false
          this.$parent.getDataList()
        } else {
          Notify({ type: 'danger', message: res.msg })
        }

      })
    },
    getbankList () {
      this.bankList.map(item => {
        this.bankListArr.push(item.label)
      })
    },
    show () {
      this.visible = true
      this.form = {}
      this.form.opening_name = '无'
      this.form.is_default = 2
    },
    pickerBank () {
      this.bankVisible = true
    },
    onConfirmBank (value) {
      this.bank = value
      let _this = this
      this.bankList.map(item => {
        if (item.label === value) {
          _this.form.bank_id = item.value;
        }
      })

      this.bankVisible = false;
    }
  }
}
</script>