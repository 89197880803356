<template>
  <div class="app-container">
    <van-cell-group class="block">
      <van-cell
        is-link
        class="cell"
        v-for="(item, index) in dataList"
        :key="index"
        @click="setDefault(item)"
      >
        <template #title>
          <div class="bank-main">
            <svg class="icon" aria-hidden="true" v-if="item.bank_id === 123">
              <use xlink:href="#van-icon-jue-yinhanglogo-5"></use>
            </svg>
            <svg class="icon" aria-hidden="true" v-if="item.bank_id === 124">
              <use xlink:href="#van-icon-jue-yinhanglogo-6"></use>
            </svg>
            <svg class="icon" aria-hidden="true" v-if="item.bank_id === 125">
              <use xlink:href="#van-icon-jue-yinhanglogo-1"></use>
            </svg>
            <div class="bank-detail">
              <div class="name">
                {{ $store.state.config.bankInfo[item.bank_id].label }}
              </div>
              <div class="desc">卡号：{{ item.card_no }}</div>
            </div>
          </div>
        </template>
        <template #right-icon>
          <van-icon
            v-if="item.is_default === 1"
            class="selectBank"
            name="success"
          />
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group class="block" style="padding: 0 20px">
      <van-button plain block type="info" @click="show"
        >添加新的银行卡</van-button
      >
    </van-cell-group>
    <div class="remark">
      <van-icon class="attention" name="warning-o" /> 向左滑非默认银行即可删除！
    </div>

    <Add ref="add" />
  </div>
</template>
<script>
import Add from './components/add'
export default {
  components: {
    Add
  },
  data () {
    return {
      form: {},
      dataList: [],
      query: {
        count: 1000
      }
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    show () {
      this.$refs.add.show()
    },
    getDataList () {
      this.$axios.post('/wxc/card/lists').then(res => {
        this.dataList = res.data.list
      })
    },
    setDefault(data){
      console.log(data)
      let form = data
      form.is_default = 1
      this.$axios.post('/wxc/card/edit', form).then(res => {
        if(res.code === 200){
          this.$router.push({name: 'CashGet'})
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.block {
  .head-title {
    margin: 0;
    padding: 20px 16px 16px;
    color: rgba(69, 90, 100, 0.6);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}

.cell {
  display: flex;
  align-items: center;
  .desc {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }
}

.bank-main {
  display: flex;
  align-items: center;
  .icon {
    width: 40px;
    height: 40px;
  }
  .bank-detail {
    padding: 0 10px;
    .name {
      font-size: 16px;
      font-weight: 600;
    }
    .desc {
      font-size: 12px;
      color: rgba(69, 90, 100, 0.6);
    }
  }
}
.selectBank {
  color: #1989fa;
  font-size: 20px;
}
.delete-button {
  height: 100%;
}
.remark {
  display: flex;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  .attention {
    margin-right: 5px;
  }
}
</style>